import "./App.css";
import React from "react";
import Navigation from "./components/Navigation";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaRegFilePdf } from "react-icons/fa6";
import m1120 from "./static/m1120.jpg";
import icon from "./static/icon.png";
import m1075 from "./static/m1075.jpg";
import m1078 from "./static/m1078a1.jpg";
import m1083 from "./static/m1083.jpg";
import m1085 from "./static/m1085.jpg";
import m1087 from "./static/m1087.jpg";
import m1089 from "./static/m1089.jpg";
import m978 from "./static/m978.jpg";
import m1101 from "./static/m1101.jpg";
import m1076 from "./static/m1076.jpg";

const Home = () => {
  const Cards = [
    {
      title: "M1120 A4",
      subtitle: "HEMTT Load Handling System(HLS)",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140573&authkey=!AFJB-fN-fj1MClY&em=2",
      img: m1120
    },
    {
      title: "TRK CGO PLS M1075",
      subtitle: "PALLETIZED LOAD SYSTEM (PLS)",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140571&authkey=!AI148iLZy19MRos&em=2",
      img: m1075,
    },

    {
      title: "M1078 A1",
      subtitle: "Light Medium Tactical Vehicle (LMTV)",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140574&authkey=!AHY18abDKC5noEU&em=2",
      img: m1078,
    },
    {
      title: "M1083 A1 P2WOW",
      subtitle: "5 ton Standard Cargo",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140577&authkey=!ANKQQKicfeEcK_w&em=2",
      img: m1083,
    },
    {
      title: "M1085 A1 P2WOW",
      subtitle: "5 ton Long Cargo",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140577&authkey=!ANKQQKicfeEcK_w&em=2",
      img: m1085,
    },
    {
      title: "M1087 A1 P2WOW",
      subtitle: "FMTV Expansible Van",
      url: "#",
      img: m1087,
    },
    {
      title: "M1089 A1 P2",
      subtitle: "HEMTT Load Handling System",
      url: "",
      img: m1089,
    },
    {
      title: "M978A4 WOW FUEL TANKER",
      subtitle: "-",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140581&authkey=!ANQYZhfwBYCHVLc&em=2",
      img: m978,
    },
    {
      title: "M1101 HMVW Trailer",
      subtitle: "TRAILER,CARGO HIGH MOBILITY, 3/4T- M1101 & 1 1/4T - M1102",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140579&authkey=!AA0h0tr0pzrgDVY&em=2",
      img: m1101,
    },
    {
      title: "M1076 PLS trailer",
      subtitle: "TRAILER, PLS 16 1/2 TON 8X20 M-1076",
      url: "https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140580&authkey=!AG5cIt55zbaBFm4&em=2",
      img: m1076,
    },
  ];
  const ImptLinks = [
    {
      name: "AR 600–55 The Army Driver and Operator Standardization Program",
      url: "https://armypubs.army.mil/epubs/DR_pubs/DR_a/pdf/web/ARN22141_R600_55_Admin_FINAL.pdf",
    },

    {
      name: "AR 385–10 The Army Safety Program",
      url: "https://home.army.mil/knox/application/files/9215/6503/0428/AR_385-10.pdf",
    },
    {
      name: "TC 21-305-20 MANUAL FOR THE WHEELED VEHICLE OPERATOR",
      url: "https://armypubs.army.mil/epubs/DR_pubs/DR_a/pdf/web/tc21_305_20.pdf",
    },
    {
      name: "TC 21-305-10 TRAINING PROGRAM FOR THE PALLETIZED LOAD SYSTEM",
      url: "http://cdn.asktop.net/wp/download/5/TC21_305_10.pdf",
    },
    {
        name: "TC 7-21.13 Soldier’s Guide",
        url: "https://home.army.mil/riley/4215/8283/3346/TC_7-21.13_SOLDIERS_GUIDE.pdf",
      },
  ];
  return (
    <>
      <Navigation />
      <Container>
        <h1>397 ENGINEERING BN FSC MOTORPOOL</h1>
        <Row className="justify-content-md-center">
          {Cards.map((card) => {
            return (
              <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                <Card key={card.title}>
                  <Card.Img
                    variant="top"
                    style={{
                      maxHeight: "130px",
                      width: "auto",
                      maxWidth: "90%",
                    }}
                    src={card.img}
                    title={`${card.title} ${card.subtitle}`}
                  />
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <Card.Text>
                      <span className={"subtitle"}>{card.subtitle}</span>
                      <a href={card.url} target={"_blank"} rel="noreferrer">
                        <img className="icon" src={icon} alt={card.title} />
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <br />
        <hr />
        <br />
        <h4>Important Links:</h4>
        <br />
        <Row className="imptlinks">
          <ul>
            {ImptLinks.map((link) => {
              return (
                <>
                  <li>
                    <FaRegFilePdf />{" "}
                    <a href={link.url} target="_blank" rel="noreferrer">
                      {link.name}
                    </a>
                  </li>
                </>
              );
            })}
          </ul>
        </Row>
      </Container>
    </>
  );
};

export default Home;
