import React from "react";
import { Nav, Navbar } from "react-bootstrap";

function Navigation() {
  return (
    <Navbar
      className="navigation enbnnavbar"
      fixed="top"
      data-bs-theme="dark"
      expand="lg"
    >
      <Navbar.Brand href="/">397 En Bn FSC</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav activeKey="/" className="justify-content-end">
          <Nav.Item>
            <Nav.Link className="justify-content-end" href="/links">
              Important Links
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
